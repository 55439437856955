const config = {
  "broker": "1",
  "name": "Hindi",
  "registrationBundle": "hindi-hindi",
  "defaultCountryName": "India",
  "defaultCountryCode": "IN",
  "fxgeoip_code": "IN",
  languageCode: 'hi',
  languageName:'Hindi',
  spokenLanguageCode:'hi',
  direction:'ltr',
  footerDisclaimer:'1155',
  brand: 'iforex'
};
module.exports = config;